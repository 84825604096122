import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import Link from 'gatsby-link'
import { MDBRow, MDBCol } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'

class ColumnAlternating extends React.Component {
  render() {
    return (
      <MDBRow
        className={`about-item align-items-center mb-5 ${
          this.props.placement === 'right' ? 'flex-row-reverse' : ''
        }`}
      >
        <MDBCol lg="4" className="order-2 order-lg-1">
        <GatsbyImage
            image={this.props.image}
            alt={this.props.alt}
            className="mt-4 rounded"
          />
        </MDBCol>
        <MDBCol lg="8" className="order-1 order-lg-2">
          <div className="about-content">
            <h3
              className={
                this.props.titleClass != null
                  ? this.props.titleClass
                  : 'font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium'
              }
            >
              <span id={this.props.anchor} className="anchor">
                {' '}
                &nbsp;{' '}
              </span>
              {this.props.title}
            </h3>
            {this.props.subtitle != null && (
              <h4
                className={
                  this.props.subtitleClass != null
                    ? this.props.subtitleClass
                    : 'font-alt font-w-700 letter-spacing-1 mb-4 title-xs-medium title-large'
                }
              >
                {this.props.subtitle}
              </h4>
            )}
            <div
              className="mt-3 pb-3 font-w-400 text-medium divlink"
              style={{ whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{ __html: this.props.description }}
            />

            {this.props.link ? (
              this.props.link.charAt(0) == '/' ? (
                <Link
                  to={this.props.link}
                  className={`mt-4 ${
                    !this.props.linktext ? this.props.colour : 'pt-3'
                  }`}
                  aria-label={this.props.title}
                >
                  {this.props.linktext ? (
                    this.props.linktext
                  ) : (
                    <FontAwesomeIcon icon={faChevronRight} />
                  )}
                </Link>
              ) : (
                <a
                  href={this.props.link}
                  className={`mt-4 effect ${!this.props.linktext &&
                    this.props.colour}`}
                  target="_blank"
                  rel="noopener"
                  aria-label={this.props.title}
                >
                  {this.props.linktext ? (
                    this.props.linktext
                  ) : (
                    <FontAwesomeIcon icon={faChevronRight} />
                  )}
                </a>
              )
            ) : null}
          </div>
        </MDBCol>
      </MDBRow>
    )
  }
}

export default ColumnAlternating